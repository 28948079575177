import React, { useEffect, useState } from "react";
import { businessNatureObj, businessTypeObj, rolesObj } from "../../utils/roles";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import Select from "react-select";

import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { addUser, updateUser } from "../../services/users.service";
import { useDispatch, useSelector } from "react-redux";
import FileUpload from "../Utility/FileUpload";
import { useParams } from "react-router-dom";
import { getUserById } from "../../redux/actions/Users/users.actions";
function EditUser() {
    const dispatch = useDispatch()
    const params = useParams()
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [status, setStatus] = useState(false);
    const [role, setRole] = useState(rolesObj.BUYER);
    const userObj = useSelector(state => state.users.userObj)
    const [rolesArr, setRolesArr] = useState([{ value: "SUBADMIN", label: "SUBADMIN" }]);

 
  
    const [permissionsArr, setPermissionsArr] = useState([
        {
          name: "Dashboard",
          url:"/Dashboard",
          permissionsObj: {
            READ: false,
      
          },
        },
        {
          name: "Category",
          url:"/Product/Category",
          permissionsObj: {
            READ: false,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
          },
        },
        {
          name: "Brand",
          url:"/Product/Brand",
          permissionsObj: {
            READ: false,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
          },
        },
        {
          name: "Products",
          url:"/Product/Product-List",
          permissionsObj: {
            READ: false,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
          },
        },
    
        // {
        //   name: "Bulk Product Upload",
        //   url:"/Product/Bulk-Product-Upload",
        //   permissionsObj: {
        //     READ: false,
        //     CREATE: false,
        //     UPDATE: false,
        //     DELETE: false,
        //   },
        // },
        {
          name: "Product Review",
          url:"/Review/Product-Review",
          permissionsObj: {
            READ: false,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
          },
        },
        {
          name: "Order Manage",
          url:"/Order/Total-Order",
          permissionsObj: {
            READ: false,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
          },
        },
        // {
        //   name: "Coupon",
        //   url:"/Coupon/Coupon",
        //   permissionsObj: {
        //     READ: false,
        //     CREATE: false,
        //     UPDATE: false,
        //     DELETE: false,
        //   },
        // },
        {
          name: "Customer Category",
          url:"/Customer-Category",
          permissionsObj: {
            READ: false,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
          },
        },
    
        {
          name: "Customer",
          url:"/Customer-list",
          permissionsObj: {
            READ: false,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
          },
        },
        {
          name: "Users",
          url:"/User-list",
          permissionsObj: {
            READ: false,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
          },
        },
    
      
        {
          name: "Banners",
          url:"/Banners",
          permissionsObj: {
            READ: false,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
          },
        },
        {
          name: "Homepage",
          url:"/HomepageCMS",
          permissionsObj: {
            READ: false,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
          },
        },
       
        {
          name: "BlogPage",
          url:"/BlogPage",
          permissionsObj: {
            READ: false,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
          },
        },
        
         {
          name: "Seo",
          url:"/Seo/View-Seo",
          permissionsObj: {
            READ: false,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
          },
        },
         {
          name: "Blog",
          url:"/Blog/post",
          permissionsObj: {
            READ: false,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
          },
        },
         {
          name: "Blog Category",
          url:"/Blog/Category",
          permissionsObj: {
            READ: false,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
          },
        },
        
         {
          name: "Contact",
          url:"/Contact",
          permissionsObj: {
            READ: false,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
          },
        },
         {
          name: "Site Setting",
          url:"/System-Setting/Setting",
          permissionsObj: {
            READ: false,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
          },
        },
    
    
      ]);
    


    const handleSubmit = async () => {
        try {
            if (name == "") {
                toastError("Name is mandatory")
                return
            }
            else if (email == "") {
                toastError("Email is mandatory")
                return
            }
            else if (phone == "") {
                toastError("Phone is mandatory")
                return
            }
            else if (phone.length != 10) {
                toastError("Invalid Phone number")
                return
            }
        

            let obj = {
                name,
                email,
                phone,
                isActive: status,
                role,
            }
            if(password && password !=""){
                obj.password = password
            }
            if (role == rolesObj.SUBADMIN) {
                obj.permissionsArr = permissionsArr
            }
            let { data: res } = await updateUser(obj, params.id);
            if (res.message) {
                toastSuccess(res.message)
            }
        }
        catch (err) {
            toastError(err)
        }
    }


    const handleCheckPermission = (index, indexX) => {
        let tempPermissionArr = permissionsArr;
        console.log(tempPermissionArr[index].permissionsObj[indexX], "tempPermissionArr[index].innerPermissionsArr[indexX]")
        tempPermissionArr[index].permissionsObj[indexX] = !tempPermissionArr[index].permissionsObj[indexX]
        setPermissionsArr([...tempPermissionArr])
    }



 


    const handleGetUserById = () => {
        dispatch(getUserById(params.id))
    }



    useEffect(() => {
        handleGetUserById()
    }, [])



    useEffect(() => {
        if (userObj) {
            setName(userObj.name ? userObj.name : "");
            setEmail(userObj.email ? userObj.email : "");
            setPhone(userObj.phone ? userObj.phone : "");
            setStatus(userObj.isActive ? userObj.isActive : false);
            setRole(userObj.role)
            if (userObj?.permissionsArr && userObj?.permissionsArr) {
                setPermissionsArr(userObj?.permissionsArr)
            }
           
           
            console.log(userObj, "userObj")
        }
    }, [userObj])




 

    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <h5 className="blue-1 mb-4">Add New User</h5>
                    <DashboardBox>
                        <form className="form row">
                   <h5 className="blue-1 mb-4">Basic Info</h5>
              <div className="col-12 col-md-4 mb-3">
                <label>
                  Name <span className="red">*</span>
                </label>
                <input name="first_name" className="form-control" type="text" required="" onChange={(e) => setName(e.target.value)} value={name} />
              </div>
              <div className="col-12 col-md-4 mb-3">
                <label>Phone</label>
                <input name="last_name" className="form-control" type="text" required="" maxLength={10} onChange={(e) => setPhone(e.target.value)} value={phone} />
              </div>
              <div className="col-12 col-md-4 mb-3">
                <label>
                  Email Address or Phone Number
                  <span className="red">*</span>
                </label>
                <input onChange={(e) => setEmail(e.target.value)} value={email} name="text" className="form-control" type="email" />
              </div>
              <div className="col-12 col-md-4 mb-3">
                <label>
                  PASSWORD (MINIMUM 8 CHARECTER)
                  <span className="red">*</span>
                </label>
                <input onChange={(e) => setPassword(e.target.value)} value={password} className="form-control" type="text" />
              </div>

              <div className="col-12 col-md-4 mb-3">
                <label>Role</label>
                <Select onChange={(e) => setRole(e.value)} options={rolesArr} />
              </div>
              <div className="col-12 mb-3">
                <label className="blue-1 fs-12">STATUS</label>
                <div className="d-flex">
                  <div className="form-check form-check-inline d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="category-status"
                      // value="true"
                      checked={status == true}
                      onChange={(e) => setStatus(true)}
                      id="active-customer"
                    />
                    <label className="form-check-label fs-14" htmlFor="active-customer">
                      Active
                    </label>
                  </div>
                  <div className="form-check form-check-inline d-flex align-items-center">
                    <input className="form-check-input" type="radio" name="category-status" value="option2" checked={status == false} onChange={(e) => setStatus(false)} id="inActive-customer" />
                    <label className="form-check-label fs-14" htmlFor="inActive-customer">
                      Inactive
                    </label>
                  </div>
                </div>
              </div>

              {role == rolesObj.SUBADMIN &&
                permissionsArr &&
                permissionsArr.length > 0 &&
                permissionsArr.map((el, index) => {
                  return (
                    <div key={index} className="col-4 col-md-4 mb-3">
                      <h5>{el.name}</h5>
                      {el.permissionsObj && 
                          Object.keys(el.permissionsObj).map((per,ind) => (
                            <div className="col-12 col-md-12 mb-3">
                            <input checked={el.permissionsObj[per]} onChange={() => handleCheckPermission(index, per)} type="checkbox" />
                            <span style={{ marginLeft: 15 }}>{per}</span>
                          </div>
                          ))
                        
                        // <>
                        //   <div className="col-12 col-md-12 mb-3">
                        //     <input checked={el.permissionsObj["CREATE"]} onChange={() => handleCheckPermission(index, "CREATE")} type="checkbox" />
                        //     <span style={{ marginLeft: 15 }}>Create</span>
                        //   </div>
                        //   <div className="col-12 col-md-12 mb-3">
                        //     <input checked={el.permissionsObj["READ"]} onChange={() => handleCheckPermission(index, "READ")} type="checkbox" />
                        //     <span style={{ marginLeft: 15 }}>Read</span>
                        //   </div>
                        //   <div className="col-12 col-md-12 mb-3">
                        //     <input checked={el.permissionsObj["UPDATE"]} onChange={() => handleCheckPermission(index, "UPDATE")} type="checkbox" />
                        //     <span style={{ marginLeft: 15 }}>Update</span>
                        //   </div>
                        //   <div className="col-12 col-md-12 mb-3">
                        //     <input checked={el.permissionsObj["DELETE"]} onChange={() => handleCheckPermission(index, "DELETE")} type="checkbox" />
                        //     <span style={{ marginLeft: 15 }}>Delete</span>
                        //   </div>
                        // </>
                      }
                    </div>
                  );
                })}

           
            </form>
            <div className="col-12 mt-2 text-center">
              <CustomButton ClickEvent={() => handleSubmit()} isBtn iconName="fa-solid fa-check" btnName="Create" />
            </div>
          </DashboardBox>
                </div>
            </section>
        </main >
    );
}

export default EditUser;
