export const rolesObj = {
    ADMIN: "ADMIN",
    USER: "USER",
    CUSTOMER: "CUSTOMER",
    SUBADMIN: "SUBADMIN",
    WHOLESALER: "WHOLESALER",
    RETAILER: "RETAILER",
};
export const discountStatus = {
    PERCENTAGE: "PERCENTAGE",
    FLATOFF: "FLATOFF",
};