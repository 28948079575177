import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { userDelete, usersGet } from "../../redux/actions/Users/users.actions";
import { updateUserKycStatus, updateUserStatus } from "../../services/users.service";
import { rolesObj } from "../../utils/roles";
import { images } from "../Images/Images";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
// import CustomerDetail from "./CustomerDetail";
import { EditModal } from "../Utility/Modal";
import SearchBox from "../Utility/SearchBox";
import tabClick from "../Utility/TabClick";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { generateFilePath } from "../Utility/utils";
function User() {
  const dispatch = useDispatch();
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const [displayUsersArr, setDisplayUsersArr] = useState([]);
  const [usersArr, setUsersArr] = useState([]);
  const [activeUsersArr, setActiveUsersArr] = useState([]);
  const [inActiveUsersArr, setInActiveUsersArr] = useState([]);
  const userArr = useSelector((state) => state.users.users);
  const role = useSelector((state) => state.auth.role);
  let permisionArr = useSelector((state) => state.auth?.permissions);
  const [isEdit, setisEdit] = useState(false);
  const [perminsionObj, setPerminsionObj] = useState("");
  const [selectedData, setSelectedData] = useState(null);

  const handleChangeActiveStatus = async (id, value) => {
    try {
      let { data: res } = await updateUserStatus(id, { status: value });
      if (res.message) {
        toastSuccess(res.message);
        handleGetAllUsers();
      }
    } catch (err) {
      if (err.response.data.message) {
        console.error(err.response.data.message);
        toastError(err.response.data.message);
      } else {
        console.error(err.message);
        toastError(err.message);
      }
    }
  };

  const handleChangeApprovalStatusStatus = async (id, value) => {
    try {
      console.log(value);
      let { data: res } = await updateUserStatus(id, { isApproved: value });
      if (res.message) {
        toastSuccess(res.message);
        handleGetAllUsers();
      }
    } catch (err) {
      if (err.response.data.message) {
        console.error(err.response.data.message);
        toastError(err.response.data.message);
      } else {
        console.error(err.message);
        toastError(err.message);
      }
    }
  };

  const handleChangeKycStatus = async (id, value) => {
    try {
      let { data: res } = await updateUserKycStatus(id, { value: value });
      if (res.message) {
        toastSuccess(res.message);
        handleGetAllUsers();
      }
    } catch (err) {
      if (err.response.data.message) {
        console.error(err.response.data.message);
        toastError(err.response.data.message);
      } else {
        console.error(err.message);
        toastError(err.message);
      }
    }

    console.log(id, value);
  };

  const handleModalSet = (e, row) => {
    e.preventDefault();
    setModalBox(true);
    setModalType("customer-detail");
    setModalName("Customer Information");
    setSelectedData(row);
  };
  const [users_columns, setUsers_columns] = useState([
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "AVATAR",
      cell: (row) => <img src={generateFilePath(row.image)} alt={row.firstName} />,
      width: "10%",
    },
    {
      name: "NAME",
      selector: (row) => `${row.firstName ? row.firstName :'User'  } ${row.lastName ? row.lastName : ''}`,
      width: "10%",
    },
    {
      name: "EMAIL",
      selector: (row) => row.email,
      width: "15%",
    },
    {
      name: "PHONE",
      selector: (row) => row.phone,
      width: "10%",
    },
    {
      name: "ROLE",
      selector: (row) => row.role,
      width: "10%",
    },
    {
      name: "IS ACTIVE",
      button: true,
      cell: (row) => <Switch onChange={(e) => handleChangeActiveStatus(row._id, e.target.checked)} checked={row.isActive} />,
      width: "8%",
    },
    {
      name: "Approval Status",
      button: true,
      cell: (row) => <Switch onChange={(e) => handleChangeActiveStatus(row._id, e.target.checked)} checked={row.isApproved} />,
      width: "10%",
    },
  ]);

  const [tabList, settabList] = useState([
    {
      tabName: "All Users",
      active: true,
    },
    {
      tabName: "Active Users",
      active: false,
    },
    {
      tabName: "Inactive Users",
      active: false,
    },
    {
      tabName: "Sub Admins",
      active: false,
    }
  ]);

  const handleGetAllUsers = () => {
    dispatch(usersGet());
  };

  useEffect(() => {
    handleGetAllUsers();
    let per = permisionArr.find((el) => el.name == 'Users');
    console.log(per,"perper")
    if(per && per?.permissionsObj){
      setPerminsionObj(per?.permissionsObj)

      if(per?.permissionsObj.CREATE){
       setisEdit(true)
      
      }
    }
  }, []);
  useEffect(() => {
    console.log(userArr);
    if (userArr && userArr.length) {
      setUsersArr(userArr.filter((el => el.role != rolesObj.USER)));
      setDisplayUsersArr(userArr);
      setActiveUsersArr(usersArr.filter((el) => el.isActive));
      setInActiveUsersArr(usersArr.filter((el) => !el.isActive));
    }
  }, [userArr]);

  const handleUserDelete = async (id) => {
    dispatch(userDelete(id));
  };

  useEffect(() => {
    if (role == rolesObj.ADMIN) {
      setUsers_columns((previousState) => [
        ...previousState,
        {
          name: "Action",
          cell: (row) => (
            <>
              <CustomButton btntype="button" ClickEvent={(e) => handleModalSet(e, row)} isBtn iconName="fa-solid fa-check" btnName="View" />
              {(row.role == rolesObj.SELLER || row.role == rolesObj.SUBADMIN) && (
                <>
                  {
                    row.role == rolesObj.SELLER &&
                    < div style={{ paddingLeft: "10px" }}>
                      <CustomButton isLink btnName="Manage Warehouse" path={`/Manage-Warehouse/${row._id}`} edit iconName="fa-solid fa-pen-to-square" />
                    </div>
                  }
                  <div style={{ paddingLeft: "10px" }}>
                    <CustomButton isLink btnName="Edit" path={`/User/User-edit/${row._id}`} edit iconName="fa-solid fa-pen-to-square" />
                  </div>
                
                </>
              )
              }
            </>
          ),
          width: "15%",
        },
      ]);
    } else if (role == rolesObj.SUBADMIN) {
      setUsers_columns((previousState) => [
        ...previousState,
        {
          name: "Action",
          cell: (row) => (
            <>
              <CustomButton btntype="button" ClickEvent={(e) => handleModalSet(e, row)} isBtn iconName="fa-solid fa-check" btnName="View" />
              {row.role == rolesObj.SELLER ||
                (row.role == rolesObj.BUYER && (
                  <div style={{ paddingLeft: "10px" }}>
                    <CustomButton btntype="button" isRedirected={true} editPath={`/Manage-Warehouse/${row._id}`} edit ClickEvent={(e) => handleModalSet(e, row)} iconName="fa-solid fa-pen-to-square" btnName="Edit" />
                  </div>
                ))}
              {
                row.role == rolesObj.SELLER &&
                < div style={{ paddingLeft: "10px" }}>
                  <CustomButton isLink btnName="Manage Warehouse" path={`/User/User-edit/${row._id}`} edit iconName="fa-solid fa-pen-to-square" />
                </div>
              }
              {/* {selectedData && <EditModal ModalBox={ModalBox} data={selectedData} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} width="max-content" />} */}
            </>
          ),
          width: "15%",
        },
      ]);
    } else {
      setUsers_columns((previousState) => [
        ...previousState,
        {
          name: "Action",
          cell: (row) => (
            <>
              {
                row.role == rolesObj.SELLER &&
                < div style={{ paddingLeft: "10px" }}>
                  <CustomButton isLink btnName="Manage Warehouse" path={`/Manage-Warehouse/${row._id}`} edit iconName="fa-solid fa-pen-to-square" />
                </div>
              }
              <CustomButton btntype="button" ClickEvent={(e) => handleModalSet(e, row)} isBtn iconName="fa-solid fa-check" btnName="View" />
              {/* {selectedData && <EditModal ModalBox={ModalBox} data={selectedData} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} width="max-content" />} */}
            </>
          ),
          width: "15%",
        },
      ]);
    }

    if (role == rolesObj.ADMIN) {
      settabList((prevState) => {
        return [
          ...prevState,
          {
            tabName: "Sub Admins",
            active: false,
          }
        
        ];
      });
    }
    // if (role == rolesObj.SUBADMIN) {
    //   settabList((prevState) => {
    //     return [
    //       ...prevState,
    //       {
    //         tabName: "Sellers",
    //         active: false,
    //       },
    //       {
    //         tabName: "Buyers",
    //         active: false,
    //       },
    //     ];
    //   });
    // }
    // if (role == rolesObj.SELLER) {
    //   settabList((prevState) => {
    //     return [
    //       ...prevState,
    //       {
    //         tabName: "Buyers",
    //         active: false,
    //       },
    //     ];
    //   });
    // }
    // if (role == rolesObj.BUYER) {
    //   settabList((prevState) => {
    //     return [...prevState];
    //   });
    // }

    return () => {
      setUsers_columns([
        {
          name: "ID",
          selector: (row, index) => index + 1,
          sortable: true,
          width: "5%",
        },
        {
          name: "NAME",
          selector: (row) => `${row.firstName ? row.firstName :'User'  } ${row.lastName ? row.lastName : ''}`,
          width: "10%",
        },
        {
          name: "EMAIL",
          selector: (row) => row.email,
          width: "15%",
        },
        {
          name: "PHONE",
          selector: (row) => row.phone,
          width: "10%",
        },
        {
          name: "ROLE",
          selector: (row) => row.role,
          width: "10%",
        },
     
        {
          name: " Status",
          button: true,
          cell: (row) => <Switch onChange={(e) => handleChangeActiveStatus(row._id, !row.isActive)} checked={row.isActive} />,
          width: "10%",
        },
        {
          name: "Delete",
          button: true,
          cell: (row) => <CustomButton isBtn btntype="button" btnName="Delete" ClickEvent={() => handleUserDelete(row?._id)} iconName="fa-solid fa-trash" />,
          width: "8%",
        },
      ]);
      settabList([
        {
          tabName: "All Users",
          active: true,
        },
        {
          tabName: "Active Users",
          active: false,
        },
        {
          tabName: "Inactive Users",
          active: false,
        },
      ]);
    };
  }, [role, usersArr]);

  // useEffect(() => {
  //   handleGetAllUsers();
  //   let per = permisionArr.find((el) => el.name == 'Users');
  //   console.log(per,"perper")
  //   if(per && per?.permissionsObj){
  //     setPerminsionObj(per?.permissionsObj)

  //     if(per?.permissionsObj.CREATE){
  //      setisEdit(true)

  //     }
  //   }
  // }, []);



  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="d-flex align-items-center justify-content-between mb-3">
          <CustomButton isLink iconName="fa-solid fa-plus" btnName="Create User" path="/User-Create" />

            {
              isEdit && (

            <CustomButton isLink iconName="fa-solid fa-plus" btnName="Create User" path="/User-Create" />

              )
            }
          </div>
          <DashboardTable>
            <div className="d-flex align-items-center justify-content-between mb-5">
              <h5 className="blue-1 m-0">All Users</h5>
              <div className="d-flex align-items-center gap-3">
                {/* <SearchBox extraClass="bg-white" /> */}
                {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="Customer CSV" path="/" small roundedPill downloadAble ClickEvent={() => downloadCSV(usersArr)} /> */}
              </div>
            </div>
            <EditModal ModalBox={ModalBox} data={selectedData} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} width="max-content" />
            <DataTable columns={users_columns} data={usersArr} pagination />
          </DashboardTable>
        </div>
      </section>
    </main>
  );
}

export default User;
