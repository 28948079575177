import React,{useEffect,useState} from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { downloadOrder, getAllOrders } from "../../../services/order.service";
import moment from "moment";
import { toastError } from "../../Utility/ToastUtils";

function InhouseOrder({ name }) {
    const [orders, setOrders] = useState([]);
  


    const handleDownload =  async() => {
      try 
      {
         let que = ``
         let url  = await downloadOrder(que);
  
         window.open(url)
  return
     } catch (error) {
         console.error(error);
         toastError(error)
     }  
    }
  
    useEffect(() => {
        getOrder();
      }, []);

    const getOrder = async () => {
        try {
          const { data: res } = await getAllOrders();
          if (res) {
            console.log(res.data);
            setOrders(res.data);
          }
        } catch (error) {
          console.error(error);
        }
      };
      const PendingOrder_columns = [
        {
          name: "SL",
          selector: (row, index) => index + 1,
          sortable: true,
          width:'5%'
        },
        {
          name: "Date",
          selector: (row) => moment(row?.createdAt).format('DD-MMM-YY HH:MM'),
        },
        {
          name: "Order ID",
          selector: (row) => row?.orderId,
          cell: (row) => row?.orderId,
          width: "200",
        },
        {
          name: "Name",
          selector: (row) =>     row?.addressObj?.firstName   +' '+    row?.addressObj?.lastName 
        },
        {
          name: "Payment Type",
          selector: (row) => row?.paymentMethod == 'COD'?'COD':'Online',
        },
        {
          name: "Total Amount",
          selector: (row) => row.totalAmount,
        },
        {
          name: "Order Status",
          button: true,
          width: "10%",
          cell: (row) => <CustomButton redBtn={row?.status == "CANCELLED"} greenBtn={row?.status != "CANCELLED"} btnName={row?.orderStatus} />,
        },
        {
          name: "Is Paid",
          button: true,
          width: "10%",
          cell: (row) => <CustomButton redBtn={row?.paymentObj?.paymentChk != 1} greenBtn={row?.paymentObj?.paymentChk == 1} btnName={row?.paymentObj?.paymentChk == 1 ? "PAID" : "PENDING"} />,
        },
        {
          name: "Action",
          cell: (row) => <CustomButton isLink btntype="button" path={`/Order/Sale-Detail?id=${row?._id}`} btnName={"View"} />,
        },
      ];
  const PendingOrder_data = [];

  return (
    <DashboardTable className="mt-4">
      <div className="d-flex gap-3 justify-content-between mb-4">
        <h5 className="blue-1 m-0">{name}</h5>
        <button className="btn btn-1 bg-black text-white rounded-pill btn-sm" type="button" onClick={()=>handleDownload()}><i className="fa fa-download"></i> Download</button>

      </div>
      <DataTable
        columns={PendingOrder_columns}
        data={orders}
        pagination
        noDataComponent="No data available in table"
      />
    </DashboardTable>
  );
}

export default InhouseOrder;
