import React from "react";
import { images } from "../Images/Images";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { rolesObj } from "../../utils/roles";

function SideBar() {
  let location = useLocation();
  let role = useSelector((state) => state.auth.role);
  let permisionArr = useSelector((state) => state.auth.permissions);
  const [sidebar_item, setsidebar_item] = useState([
    {
      isrotated: true,
      active: true,
      name: "dashboard",
      path: "/",
      icon: "ion-grid",
      children: [],
    },
    {
      isrotated: false,
      active: false,
      name: "products",
      path: "/Dashboard",
      icon: "fa-brands fa-product-hunt",
      children: [
        // {
        //   name: "Product Category",
        //   path: "/Product/ProductCategory",
        //   active: false,
        // },
        {
          name: "Category",
          path: "/Product/Category?level=1",
          active: false,
        },
        {
          name: "Sub Category",
          path: "/Product/Category?level=2",
          active: false,
        },
        {
          name: "Brand",
          path: "/Product/Brand",
          active: false,
        },
        // {
        //   name: "Attribute",
        //   path: "/Product/Attribute",
        //   active: false,
        // },
        // {
        //   name: "Add New Product",
        //   path: "/Product/AddProduct",
        //   active: false,
        // },
        // {
        //   name: "Bulk Product Upload",
        //   path: "/Product/Bulk-Product-Upload",
        //   active: false,
        // },
        {
          name: "Product List",
          path: "/Product/Product-List",
          active: false,
        },
        // {
        //   name: "Recent View Config",
        //   path: "/Product/Config",
        //   active: false,
        // },
      ],
    },
    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Discount Coupons",
    //   path: "/Discounts/View",
    //   icon: "ion-card",
    //   children: [],
    // },

    {
      isrotated: false,
      active: false,
      name: "Customer",
      path: "/Dashboard",
      icon: "fa-solid fa-users",
      children: [
        {
          name: "View Customer Categories",
          path: "/Customer-Category",
          active: false,
        },
        {
          name: "All Customer",
          path: "/Customer-list",
          active: false,
        },

        {
          name: "All User",
          path: "/User-list",
          active: false,
        },
      ],
    },
    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Customer Orders",
    //   path: "/User-Order/View-All",
    //   icon: "fa-solid fa-users",
    //   children: [],
    // },
    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Chats",
    //   path: "/Chats/AllChats",
    //   icon: "fa-sharp fa-solid fa-comment",
    //   children: [],
    // },
    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Email Config",
    //   path: "/EmailConfig",
    //   icon: "fa-sharp fa-solid fa-comment",
    //   children: [],
    // },
    {
      isrotated: false,
      active: false,
      name: "Order Manage",

      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      icon: "ion-android-cart",
      children: [
        {
          name: "Total Order",
          path: "/Order/Total-Order",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Chats",
      path: "/Chats/AllChats",
      icon: "fa-sharp fa-solid fa-comment",
      children: [],
    },
    {
      isrotated: false,
      active: false,
      name: "Frontend CMS",

      roleArr: ["ADMIN", "SUBADMIN"],
      icon: "fa-solid fa-user",
      children: [
        {
          name: "Banners",
          path: "/Banners",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Homepage",
          path: "/HomepageCMS",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },

        {
          active: false,
          name: "Seo",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          path: "/Seo/View-Seo",
          icon: "fa fa-list",
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Query",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],

      icon: "fa-solid fa-cog",
      children: [
        {
          name: "Contact",
          path: "/Contact",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Settings",
      roleArr: ["SELLER", "SUBADMIN", "ADMIN"],

      icon: "fa-solid fa-user",
      children: [
        {
          name: "Site Setting",
          path: "/System-Setting/Setting",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Report",
      path: "/Report",
      icon: "fa-solid fa-users",
      children: [
        {
          name: "View Product Sale",
          path: "/Report/Product-Sale",
          active: false,
        },
        {
          name: "View Product Stock",
          path: "/Report/Product-Stock",
          active: false,
        },
        // {
        //   name: "View Coupon Sale",
        //   path: "/Report/Coupon-Sale",
        //   active: false,
        // },
        {
          name: "View New Customers",
          path: "/Report/New-Customers",
          active: false,
        },
        // {
        //   name: "View Customers by category",
        //   path: "/Report/Customers-Orders",
        //   active: false,
        // },
        // {
        //   name: "View Prouduct Searches",
        //   path: "/Report/Prouduct-Searches",
        //   active: false,
        // },
      ],
    },
  ]);

  const isRotating = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (index === i) {
        el.isrotated = !el.isrotated;
        el.active = true;
      } else {
        el.active = false;
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

  const childActive = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (el.children.length > 0) {
        el.children.map((item, childIndex) => {
          if (childIndex === i) {
            item.active = true;
          } else {
            item.active = false;
          }
          return item;
        });
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

 const handleCheckIfElementIsAllowed = (element) => {
    console.log(element, "element");
    let tempValue = false

    if(role == rolesObj.ADMIN){
      return true
    }

    if(element.children && element.children.length > 0){
        tempValue = element.children.some((el) => permisionArr.some(
          (ele) => (ele.url.trim().toLowerCase() == el.path.trim().toLowerCase() && (ele.permissionsObj.CREATE || ele.permissionsObj.READ ))
        ))
      }
      else{
        console.log(element, "clfg")
        if(element.path){
          tempValue = permisionArr.some((ele) => ele.url.trim().toLowerCase() == element.path.trim().toLowerCase() && (ele.permissionsObj.CREATE || ele.permissionsObj.READ))
        }
      }
      if(tempValue){
        console.log(tempValue,"tempValue", element,role)
      }
      return tempValue
  };


  return (
    <div id="sidebar">
      <div className="main-logo">
        <img src={images.logo} alt="" />
      </div>
      <ul className="sidebar-menu" id="sidebarMenu">
      {sidebar_item &&
          sidebar_item?.filter(el=> handleCheckIfElementIsAllowed(el))?.map((item, i) => {
            if (
              typeof array === "undefined" && item.children.length === 0) {
            return (
              <li key={`sidebar_item_${i}`}>
                <Link
                  to={item.path}
                  className={item.active ? "active" : ""}
                  onClick={() => isRotating(i)}
                >
                  <i className={item.icon}></i>
                  <p className="mb-0">{item.name}</p>
                </Link>
              </li>
            );
          } else {
            return (
              <li key={`sidebar_item_${i}`}>
                <Link
                  to={`#sidebar_item_children_${i}`}
                  className={
                    item.active || location === item.path ? "active" : ""
                  }
                  data-bs-toggle="collapse"
                  aria-expanded={item.active}
                  aria-controls={`sidebar_item_children_${i}`}
                  role="button"
                  onClick={() => isRotating(i)}
                >
                  <i className={item.icon}></i>
                  <p className="mb-0">
                    {item.name}
                    {item.isrotated ? (
                      <i className="ion-arrow-up-b pe-3"></i>
                    ) : (
                      <i className="ion-arrow-down-b pe-3"></i>
                    )}
                  </p>
                </Link>
                <ul
                  className="collapse"
                  id={`sidebar_item_children_${i}`}
                  data-bs-parent="#sidebarMenu"
                >
                  {item.children.map((child, index) => {
                    return (
                      <li key={`${child.name}_${index}`}>
                        <Link
                          to={child.path}
                          className={
                            child.active || location === child.path
                              ? "active"
                              : ""
                          }
                          onClick={() => childActive(index)}
                        >
                          {child.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
}

export default SideBar;
