import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import { images } from "../../Images/Images";
import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { CONTACTGet, SetCONTACTObj ,CONTACTDelete} from "../../../redux/actions/Contact/Contact.actions";
import { generateFilePath } from "../../Utility/utils";
import { toastError } from "../../Utility/ToastUtils";
import { downloadContact } from "../../../services/contact.service";
import { Modal, Box } from "@mui/material";

function Contact() {
  const dispatch = useDispatch();

  const bannerArr = useSelector((state) => state.contact.contacts);

  let permisionArr = useSelector((state) => state.auth.permissions);
  const [isEdit, setisEdit] = useState(false);
  const [perminsionObj, setPerminsionObj] = useState("");
  const [customerData, setcustomerData] = useState();
  const [detailShow, setDetailShow] = useState(false);


  const handleDetailShow = (row) => 
  {
    setcustomerData(row);
    setDetailShow(true);
  }

  useEffect(() => {
    dispatch(CONTACTGet());

      let per = permisionArr.find((el) => el.name == 'Seo');
      console.log(per,"perper")
      if(per && per?.permissionsObj){
        setPerminsionObj(per?.permissionsObj)
  
        if(per?.permissionsObj.CREATE){
         setisEdit(true)
  
        }
      }
  }, [])
  const handleEdit = (row) => {
    console.log(row , "row row row");
    dispatch(SetCONTACTObj(row));
  };

  const handleBlogDelete = (row) => {
    let confirm =window.confirm("Do you really want to delete this item?")
    if (confirm) {
      dispatch(CONTACTDelete(row));
    }

  };

  const handleDownload =  async() => {
    try 
    {
       let query = ''
       let url  = await downloadContact(query);

       window.open(url)
return
   } catch (error) {
       console.error(error);
       toastError(error)
   }  
  }
  const brand_columns = [
    {
      name: "Date",
      selector: (row, index) => new Date(row.createdAt).toDateString(),
      sortable: true,
      width: "15%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "200px",
    },
    {
      name: "Email",
      grow: 0,
      selector: (row) => row.email,
      width: "15%",
    },
    {
      name: "Phone",
      button: true,
      selector: (row) => row.phone,
      width: "15%",
    },

    {
      name: "Action",
      width: "20%",
      cell: (row) => (
        <>
          <ActionIcon
            isRedirected={true}
            remove={perminsionObj.DELETE}
            onDeleteClick={() => handleBlogDelete(row?._id)}
            deletePath="/Contact"
            Uniquekey={row.id}
          />
          {"  "}
          <button
            role="button"
            className="btn btn-1 bg-black text-white rounded-pill btn-sm ms-3"
            onClick={() => handleDetailShow(row)}
          >
            Details
          </button>
        </>
      ),
    },
  ];

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Contact List</h5>
                <div className="d-flex align-items-center gap-3">
                  <button
                    className="btn btn-1 bg-black text-white rounded-pill btn-sm"
                    type="button"
                    onClick={() => handleDownload()}
                  >
                    <i className="fa fa-download"></i> Download
                  </button>
                  {/* <SearchBox extraClass="bg-white" /> */}
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={brand_columns}
                  data={bannerArr && bannerArr.length > 0 ? bannerArr : []}
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>

      <Modal
        open={detailShow}
        onClose={() => setDetailShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box">
          <div className="modal-container" style={{ width: "600px" }}>
            <div className="modal-body m-0 p-0">
              <div className="modal-header p-3">
                <h5>Enquiry Details</h5>
                <CustomButton
                  isBtn
                  btntype="button"
                  iconName="ion-close-circled text-white"
                  changeClass="border-0 bg-transparent rounded-circle modal-close"
                  ClickEvent={(e) => {
                    e.preventDefault();
                    setDetailShow(false);
                  }}
                />
              </div>

              <div className="container">
                <div className="row">
                  <div className={`col-12 col-md-12 p-5`}>
                    <div className="customer-profile">
                      <ul className="blue-1 fs-14">
                        <li>
                          <span className="fw-600">
                            Name <span>:</span>
                          </span>
                          {customerData?.name}
                        </li>
                        <li>
                          <span className="fw-600">
                            Email <span>:</span>
                          </span>
                          {customerData?.email}
                        </li>

                        <li>
                          <span className="fw-600">
                            Phone <span>:</span>
                          </span>
                          {customerData?.phone}
                        </li>

                        <li>
                          <span className="fw-600">
                            Title <span>:</span>
                          </span>
                          {customerData?.title}
                        </li>

                        <li>
                          <span className="fw-600">
                            Message <span>:</span>
                          </span>
                          {customerData?.message}
                        </li>
                        <li>
                          <span className="fw-600">
                            Image <span>:</span>
                          </span>
                         <img src={generateFilePath(customerData?.image)}  width="100px" heigth="100px" alt="NoImage " />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </main>
  );
}

export default Contact;
