import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import { images } from "../../Images/Images";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import { downloadCSV } from "../../Utility/CSV";
import SearchBox from "../../Utility/SearchBox";
import { DashboardTable } from "../../Utility/DashboardBox";

import { useSelector, useDispatch } from "react-redux";
import { BrandDelete, BrandGet, SetBrandObj } from "../../../redux/actions/Brand/brand.actions";
import { generateFilePath } from "../../Utility/utils";
function Brand() {
  const dispatch = useDispatch();
  const brandArr = useSelector((state) => state.brand.brands);
  const [displayBrandArr, setDisplayBrandArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [query, setQuery] = useState("");
  let permisionArr = useSelector((state) => state.auth.permissions);
    const [isEdit, setisEdit] = useState(false);
    const [perminsionObj, setPerminsionObj] = useState("");
  const handleInit = () => {
    dispatch(BrandGet());
    
  };

  const handleEdit = (row) => {
    dispatch(SetBrandObj(row));
  };

  const handleBrandDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this data ?")) {
      dispatch(BrandDelete(id));

    }
  };
  useEffect(() => {
    handleInit();
    let per = permisionArr.find((el) => el.name == 'Brand');
    console.log(per,"perper")
    if(per && per?.permissionsObj){
      setPerminsionObj(per?.permissionsObj)

      if(per?.permissionsObj.CREATE){
       setisEdit(true)

      }
    }
  }, []);
  useEffect(() => {
    if (brandArr && brandArr.length > 0) {
      setDisplayBrandArr([...brandArr])
      setMainArr([...brandArr])
    }
  }, [brandArr]);

  const brand_columns = [
    {
      name: "ID",
      cell: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "Logo",
      grow: 0,
      minWidth: "200px",
      maxWidth: "210px",
      cell: (row) => <img height="84px" width="56px" alt={row?.name} src={generateFilePath(row.imageUrl)} />,
    },
    {
      name: "Status",
      button: true,
      minWidth: "200px",
      maxWidth: "210px",
      cell: (row) => row.statusInfo,
    },

    {
      name: "Action",
      minWidth: "200px",
      maxWidth: "210px",
      cell: (row) => <ActionIcon isRedirected={true} onEditClick={() => handleEdit(row)} editPath="/Product/Brand-Create" onDeleteClick={() => handleBrandDelete(row._id)} deletePath="/Product/Brand" remove={perminsionObj.DELETE} edit={perminsionObj.UPDATE} Uniquekey={row._id} />,
    },
  ];





  const handleSearch = (queryValue) => {
    setQuery(queryValue)
    let tempArr = mainArr
    tempArr = tempArr.filter(el => `${el?.name}`.toLowerCase().includes(`${queryValue}`.toLowerCase()))
    setDisplayBrandArr([...tempArr])
  }

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Brand List</h5>
                <div className="d-flex align-items-center gap-3">
                {
                                isEdit && (
                           <CustomButton isLink iconName="fa-solid fa-plus" btnName="ADD NEW BRAND" path="/Product/Brand-Create" small roundedPill />

                                )}
                  {/* <CustomButton isLink iconName="fa-solid fa-plus" btnName="BULK BRAND UPLOAD" path="/Product/Bulk-Brand-Upload" small roundedPill /> */}
                  {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="BRAND CSV" path="/" small roundedPill downloadAble ClickEvent={() => downloadCSV(brand_data)} /> */}
                  <SearchBox query={query} setQuery={handleSearch} extraClass="bg-white" />
                </div>
              </div>
              {displayBrandArr && displayBrandArr.length > 0 && (
                <DashboardTable>
                  <DataTable columns={brand_columns} data={displayBrandArr} pagination />
                </DashboardTable>
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Brand;
